body {
  background-color: rgb(245, 245, 245);
  overflow-x: hidden;
}
header {
  position: relative;
  display: block;
  width: 100%;
  background: black;
  color: white;
  height: 90px;
}
.logo {
  position: absolute;
  top: 50%; left: 15px;
  transform: translateY(-50%);
}
nav {
  position: absolute;
  top: 50%; right: 15px;
  transform: translateY(-50%);
}
nav, nav ul, nav ul li {
  margin: 0;
  padding: 0;
}
nav ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 10px;
}
.lastmenu {
  margin: 0;
}
nav ul li a {
  font-size: 19px;
  line-height: normal;
  color: white;
}
a {
  text-decoration: none;
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
  cursor: pointer;
  color: white;
}
a:hover, a:active, a:focus{
  text-decoration: none;
  color: red;
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
footer {
  background-color: black;
  color: white;
}
.app-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px !important;
  padding-left: 10px;
}
.stocktable, .stockth, .stocktd {
  background-color: turquoise;
  border: 1px solid black  
}
.stockth, .stocktd {
  padding-right: 5px;
  padding-left: 5px;
}
.chart1, .chart3 {
  /* background-color: white; */
  border: 0px solid black  
}
.chart2 {
  /* background-color: white; */
  border: 0px solid black  
}
.selectCrypto {
    position: relative;
    display: flex;
    width: 25em;
    height: 3em;
    line-height: 3;
    background: #2c3e50;
    overflow: hidden;
    border-radius: .25em;
}
.cryptoAlign {
  padding-right: 2px;
  width: 50px;  
}
.cryptoDateInput {
  margin-bottom: 6px;
  height: 3em;
  line-height: 3;
  width: 100px;
}
textarea {
  overflow-y: scroll;
  height: 100px;
  resize: none; /* Remove this if you want the user to resize the textarea */
}
.quoteTitle {
  color: "black";
  font-weight: 600;
  font-size: 14px;
  font-family: Arial, Verdana, sans-serif; color: #111111;
}
.convertResult {
  margin-top: 20px;
  margin-bottom: 40px;
}

/* select drop-down field */
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none;
  outline: 0;
  box-shadow: none;
  border: 0 !important;
  background: #2c3e50;
  background-image: none;
}
/* Remove IE arrow */
select::-ms-expand {
  display: none;
}
/* Custom Select */
.select {
  position: relative;
  display: flex;
  width: 20em;
  height: 3em;
  line-height: 3;
  background: #2c3e50;
  overflow: hidden;
  border-radius: .25em;
}
.selectStock {
  position: relative;
  display: flex;
  width: 25em;
  height: 3em;
  line-height: 3;
  background: #2c3e50;
  overflow: hidden;
  border-radius: .25em;
}
select {
  flex: 1;
  padding: 0 .5em;
  color: #fff;
  cursor: pointer;

  border: 1px solid #c4c4c4;
  /* border-radius: 5px; */
  background-color: #2c3e50;
  /* padding: 3px 5px; */
}
/* Arrow */
.select::after {
  content: '\25BC';
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 1em;
  background: #34495e;
  cursor: pointer;
  pointer-events: none;
  -webkit-transition: .25s all ease;
  -o-transition: .25s all ease;
  transition: .25s all ease;
}
/* Transition */
.select:hover::after {
  color: #f39c12;
}
/* Input Quantity Select in homePage */
.input {
  position: relative;
  display: flex;
  width: 10em;
  height: 3em;
  line-height: 3;
  background: #2c3e50;
  overflow: hidden;
  border-radius: .25em;
  color: white;
  margin-bottom: 26px;
}
.dateInput {
  margin-bottom: 26px;
  height: 3em;
  line-height: 3;
  width: auto;
}
label {
  display: block;
}
input {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #2c3e50;
  padding: 3px 5px;
  box-shadow: inset 0 3px 6px rgba(0,0,0,0.1);
  width: 190px;
  color: white;
}
.currencyAlign {
  padding-right: 10px;
}
.btn {
  height: 3em;
}
.stockAlign {
  padding-right: 10px;
}
hr {
  width: 100%;
  border-top: 1px dotted black;
}
.even {
  background-color: rgb(230, 232, 230);
  width: 100%;
}
.wtbl {
  width: 1008px;
}
.padding {
  padding-left: 5px;
}

.cryptoTable, .cryptoth, .cryptotd {
  background-color: turquoise;
  border: 1px solid black  
}
.cryptoth, .cryptotd {
  padding-right: 5px;
  padding-left: 5px;
  width: 90px;
}